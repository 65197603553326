<template>
    <div v-loading="loading" class="refundDetails content-index">
        <div class="back-box" @click="goBack">
            <span class="el-icon-back icon"></span>
            <span class="title">退款信息</span>
        </div>
        <div class="refundDetails-content">
            <div class="refundDetails-item">
                <div class="item-title">
                    <span class="title-icon"></span>
                    <span class="title-text">退款信息</span>
                </div>
                <div class="item-main">
                    <div class="main-text">
                        退款编号: {{ refundData.refundExamineId }}
                    </div>
                    <div class="main-text">
                        申请退款时间：{{ refundData.createdTime }}
                    </div>
                    <div class="main-text">退款方式：{{ refundData.payWayDesc }}</div>
                </div>
                <div class="item-main">
                    <div class="main-text">操作人：{{ refundData.storeName }}</div>
                    <div class="main-text"></div>
                    <div class="main-text"></div>
                </div>
            </div>
            <div class="refundDetails-item">
                <div class="item-title">
                    <span class="title-icon"></span>
                    <span class="title-text">订单信息</span>
                </div>
                <div class="item-main">
                    <div class="main-text">订单编号：{{ refundData.orderNumber }}</div>
                    <div class="main-text">下单时间：{{ refundData.orderTime }}</div>
                    <div class="main-text">用户名称：{{ refundData.userName }}</div>
                </div>
                <div class="item-main">
                    <div class="main-text">用户手机号：{{ refundData.userPhone }}</div>
                    <div class="main-text">
                        订单合计退款: {{ refundData.orderPrice }}元
                    </div>
                    <div class="main-text">
                        审核状态：{{ refundData.examineStatusDesc }}
                    </div>
                </div>
                <div class="item-main">
                    <div class="main-text">审核说明：{{ refundData.examineDesc }}</div>
                </div>
                <div class="item-main">
                    <div class="main-text">退款状态：{{ refundData.statusDesc }}</div>
                    <div v-if="refundData.status == 1" class="main-text">
                        退款失败原因：{{ refundData.failReason }}
                    </div>
                    <div v-else class="main-text"></div>
                    <div class="main-text"></div>
                </div>
            </div>
            <div class="refundDetails-item">
                <div class="item-title">
                    <span class="title-icon"></span>
                    <span class="title-text">退款明细</span>
                </div>
                <div class="item-main">
                    <Table
                            :button-type="false"
                            :height="'calc(100vh - 500px)'"
                            :orientation="'center'"
                            :page="true"
                            :serial-number="true"
                            style="width: 100%"
                            :table-data="tableData"
                            :title-list="titleListData"
                    />
                </div>
            </div>
        </div>
        <div class="footer-button">
            <div class="button-box">
                <el-button size="medium" @click="cancel">取消</el-button>
                <el-button
                        v-if="refundData.examineStatusDesc == '审核通过'&&refundData.statusDesc=='成功'"
                        size="medium"
                        type="primary"
                        @click="printRefundPaper"
                >
                    打印小票
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
import Table from "@/components/table2";
import {getRefundExamineDetail} from "@/api/clothes/order";
import {getLodop} from "@/print/LodopFuncs";

export default {
    name: "RefundDetails",
    components: {
        Table,
    },
    data() {
        return {
            loading: false,
            tableData: [],
            titleListData: [
                {
                    prop: "outClothesNum",
                    label: "衣物号",
                },
                {
                    prop: "clothesName",
                    label: "衣物名称",
                },
                {
                    prop: "clothesStatus",
                    label: "衣物状态",
                },
                {
                    prop: "refundFee",
                    label: "可退金额(元)",
                },
                {
                    prop: "estimateRefundFee",
                    label: "实际退款金额(元)",
                },
                {
                    prop: "refundReason",
                    label: "退款原因",
                },
            ],
            refundExamineId: null,
            refundData: {},
        };
    },
    created() {
        this.refundExamineId = this.$route.query.refundExamineId;
        this.getRefundExamineDetailData();
    },
    methods: {
        goBack() {
            this.$router.back();
        },
        // 获取退款数据详情
        getRefundExamineDetailData() {
            this.loading = true;
            getRefundExamineDetail({refundExamineId: this.refundExamineId})
                .then(({data}) => {
                    if (data.code == 0) {
                        this.refundData = data.data;
                        if (!this.refundData.status) {
                            this.refundData.statusDesc = "无";
                        } else {
                            this.refundData.statusDesc =
                                this.refundData.status == 1 ? "失败" : "成功";
                        }
                        this.tableData = data.data.refundExamineClothesList;
                    } else {
                        this.$message.error(data.msg);
                    }
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        // 取消
        cancel() {
            this.$router.back();
        },
        // 打印退款
        printRefundPaper() {
            let LODOP = getLodop();
            LODOP.SET_LICENSES(
                "",
                "660FDFD7D9F7F7101A272F2EB57C306A",
                "C94CEE276DB2187AE6B65D56B3FC2848",
                ""
            );

            LODOP.PRINT_INIT("收银小票"); //首先一个初始化语句
            LODOP.SET_PRINT_PAGESIZE(3, "75mm", "20mm", "收银小票");
            //门店信息
            LODOP.ADD_PRINT_TEXT(
                "0mm",
                "0mm",
                "75mm",
                "4mm",
                this.refundData.store.storeName
            );
            LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 14);
            LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
            //门店电话
            LODOP.ADD_PRINT_TEXT(
                "6mm",
                "0mm",
                "75mm",
                "3mm",
                "门店电话：" + this.refundData.store.storePhone
            );
            LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
            //门店地址
            if (this.refundData.store.address.length > 16) {
                LODOP.ADD_PRINT_TEXT(
                    "10mm",
                    "0mm",
                    "75mm",
                    "3mm",
                    "地址：" + this.refundData.store.address.substring(0, 16)
                );
                LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
                LODOP.ADD_PRINT_TEXT(
                    "14mm",
                    "0mm",
                    "75mm",
                    "3mm",
                    this.refundData.store.address.substring(16)
                );
                LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
            } else {
                LODOP.ADD_PRINT_TEXT(
                    "10mm",
                    "0mm",
                    "75mm",
                    "3mm",
                    "地址：" + this.refundData.store.address
                );
                LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
            }

            //线
            LODOP.ADD_PRINT_LINE("16mm", 0, "16mm", "75mm", 3, 0);
            //衣物信息标题
            LODOP.ADD_PRINT_TEXT("18mm", "0mm", "75mm", "4mm", "衣物退款信息");
            LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);
            LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
            //订单号条形码
            LODOP.ADD_PRINT_BARCODE(
                "24mm",
                "10mm",
                "55mm",
                "9mm",
                "128Auto",
                this.refundData.orderNumber
            );
            LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
            LODOP.SET_PRINT_STYLEA(0, "ShowBarText", 0);
            //订单号
            LODOP.ADD_PRINT_TEXT(
                "33mm",
                "0mm",
                "75mm",
                "4mm",
                this.refundData.orderNumber
            );
            LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);

            let height = 33;

            let refundClothesList = this.refundData.refundExamineClothesList;
            //衣物列表：名称 服务 件数 原价 实收
            if (refundClothesList.length > 0) {
                LODOP.ADD_PRINT_TEXT("38mm", "0mm", "75mm", "4mm", "名称");
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);
                LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
                LODOP.ADD_PRINT_TEXT("38mm", "15mm", "75mm", "4mm", "项目");
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);
                LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
                LODOP.ADD_PRINT_TEXT("38mm", "30mm", "75mm", "4mm", "件数");
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);
                LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
                LODOP.ADD_PRINT_TEXT("38mm", "45mm", "75mm", "4mm", "实收");
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);
                LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
                LODOP.ADD_PRINT_TEXT("38mm", "60mm", "75mm", "4mm", "实退");
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);
                LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
                height = height + 5;
                height = height + refundClothesList.length * 4;
            }

            //衣物列表
            for (let i = 0; i < refundClothesList.length; i++) {
                let clothes = refundClothesList[i];
                LODOP.ADD_PRINT_TEXT(
                    42 + i * 4 + 1 + "mm",
                    "0mm",
                    "75mm",
                    "4mm",
                    clothes.clothesName
                );
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
                // LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
                LODOP.ADD_PRINT_TEXT(
                    42 + i * 4 + 1 + "mm",
                    "15mm",
                    "75mm",
                    "4mm",
                    clothes.serviceName
                );
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
                // LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
                LODOP.ADD_PRINT_TEXT(42 + i * 4 + 1 + "mm", "30mm", "75mm", "4mm", "1");
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
                // LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
                LODOP.ADD_PRINT_TEXT(
                    42 + i * 4 + 1 + "mm",
                    "45mm",
                    "75mm",
                    "4mm",
                    clothes.refundFee
                );
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
                // LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
                LODOP.ADD_PRINT_TEXT(
                    42 + i * 4 + 1 + "mm",
                    "60mm",
                    "75mm",
                    "4mm",
                    clothes.estimateRefundFee
                );
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
                // LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
            }

            //注意：*衣物详细信息请进入洗衣通小程序查看*
            height = height + 6;
            LODOP.ADD_PRINT_TEXT(
                height + "mm",
                "0mm",
                "75mm",
                "4mm",
                "*衣物详细信息请进入洗衣通小程序查看*"
            );
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 8);
            LODOP.SET_PRINT_STYLEA(0, "Bold", 1);
            LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);

            height = height + 6;
            // 线
            LODOP.ADD_PRINT_LINE(height + "mm", 0, height + "mm", "75mm", 3, 0);

            //收银信息
            height = height + 4;
            LODOP.ADD_PRINT_TEXT(height + "mm", "0mm", "75mm", "4mm", "退款信息");
            LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);
            LODOP.SET_PRINT_STYLEA(0, "Bold", 1);

            //收银详情
            height = height + 6;
            LODOP.ADD_PRINT_TEXT(
                height + "mm",
                "0mm",
                "75mm",
                "4mm",
                "客户姓名：" + this.refundData.userName
            );
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
            LODOP.ADD_PRINT_TEXT(
                height + "mm",
                "37.5mm",
                "75mm",
                "4mm",
                "联系电话：" +
                this.refundData.userPhone.replace(
                    this.refundData.userPhone.substring(3, 7),
                    "****"
                )
            );
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
            height = height + 5;
            LODOP.ADD_PRINT_TEXT(
                height + "mm",
                "0mm",
                "75mm",
                "4mm",
                "退款总额：" + this.refundData.orderPrice
            );
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
            LODOP.ADD_PRINT_TEXT(
                height + "mm",
                "37.5mm",
                "75mm",
                "4mm",
                "操作店长：" + this.refundData.storeName
            );
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);

            height = height + 5;
            LODOP.ADD_PRINT_TEXT(
                height + "mm",
                "0mm",
                "75mm",
                "4mm",
                "退款时间：" + this.getTime()
            );
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 9);
            //线
            height = height + 6;
            // 线
            LODOP.ADD_PRINT_LINE(height + "mm", 0, height + "mm", "75mm", 3, 0);

            //二维码
            height = height + 5;
            LODOP.ADD_PRINT_BARCODE(
                height + "mm",
                "25mm",
                "30mm",
                "30mm",
                "QRCode",
                "http://weixin.qq.com/r/6Sk8JNrEeYcGrZ5H93z7"
            );
            height = height + 25;

            //注：扫码进入小程序查看衣物详细信息
            LODOP.ADD_PRINT_TEXT(
                height + "mm",
                "0mm",
                "75mm",
                "4mm",
                "扫码进入小程序查看衣物详细信息"
            );
            LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 8);

            //投诉服务电话：400-816-5008
            height = height + 5;
            LODOP.ADD_PRINT_TEXT(
                height + "mm",
                "0mm",
                "75mm",
                "4mm",
                "投诉服务电话：400-816-5008"
            );
            LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 8);

            LODOP.SET_PRINTER_INDEX(-1);
            LODOP.PRINT();
        },
        getTime() {
            var date1 = new Date();
            var year = date1.getFullYear();
            var month = date1.getMonth() + 1;
            var day = date1.getDate();
            var hours = date1.getHours();
            var minutes = date1.getMinutes();
            var seconds = date1.getSeconds();
            return (
                year +
                "年" +
                month +
                "月" +
                day +
                "日" +
                hours +
                ":" +
                minutes +
                ":" +
                seconds
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.refundDetails {
  position: relative;
  padding: 20px;
  padding-bottom: 100px;

  .back-box {
    display: flex;
    align-items: center;

    .icon {
      font-size: 20px;
      color: #8e8e8e;
    }

    .title {
      padding-left: 10px;
      font-size: 18px;
      color: #8e8e8e;
    }
  }

  .refundDetails-content {
    .refundDetails-item {
      margin-top: 10px;

      .item-title {
        display: flex;
        align-items: center;
        padding-bottom: 4px;

        .title-icon {
          height: 16px;
          width: 4px;
          border-radius: 2px;
          background-color: #353bfe;
          margin-right: 5px;
        }

        .title-text {
          color: #101010;
          font-size: 16px;
        }
      }

      .item-main {
        display: flex;
        padding: 5px 10px;

        .main-text {
          flex: 1;
        }
      }
    }
  }

  .footer-button {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100px;
    width: 100%;
    background-color: #fff;

    .button-box {
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

::v-deep .el-table__body td.el-table__cell {
  height: 50px;
}
</style>
